import React, {Component} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {content} from "../../globals/content";
import {AvatarGroup, Tooltip} from "@mui/material";

export default class SessionSelector extends Component {
    state = {
        rows: [],
    }


    componentDidMount()
    {
        this.loadSessions()
    }

    loadSessions = () => {
        let self = this;
        let result = JSON.parse(localStorage.getItem("chatSessions"));
        let sessions = []
        if (self.props.sessionType) {
            result.forEach((session) => {
                if (session.sessionType === self.props.sessionType) {
                    sessions.push(session)
                }
            })
        } else {
            sessions = result;
        }
        self.setState({rows: sessions})
    }


    formatMembers = (members) => {
        let membersString = ""
        members.forEach((member) => {
            membersString += member + ", "
        })
        return membersString
    }

    joinSession = (row) => {
        let lang = "";
        row.sessionMembers.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")){
                console.log("found")
                lang = member.language;
            }
        })

        this.props.joinSession(row.id, lang, row)
    }


    render() {
        return (
            <div style={{ padding: "1px"
            }}>
                {this.state.rows.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{ }} >
                        <TableHead>
                            <TableRow key={"tablehead"}>
                                <TableCell component="th">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].nameHeader}</TableCell>
                                <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].memberHeader}</TableCell>
                                <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].languageHeader}</TableCell>

                                <TableCell component="th"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows.map((row) => (

                                    <TableRow key={row.id}>
                                        <TableCell align="left" style={{fontWeight: "bold"}}><Link onClick={() => {this.joinSession(row)}}>{row.name}</Link></TableCell>
                                        <TableCell align="left">
                                            <AvatarGroup max={3}>
                                            {row.sessionMembers.map((member) => {
                                                return (<Tooltip title={member.displayName}><Avatar
                                                    key={member.userId}
                                                    sx={{
                                                        bgcolor: "#eee",
                                                        width: 22,
                                                        height: 22,
                                                        fontSize: ".8rem",
                                                        color: "#333",
                                                        textAlign: "center",
                                                        paddingTop: "5px",

                                                        display: "inline-block"
                                                    }}
                                                    alt={member.displayName}
                                                >{member.displayName[0]}</Avatar></Tooltip>)
                                            })
                                            }
                                            </AvatarGroup>
                                        </TableCell>
                                        <TableCell align="left">{row.languages.join(", ")}</TableCell>
                                        <TableCell align="left"><Link onClick={() => {this.joinSession(row)}}>
                                            <OpenInNewIcon style={{fontSize: "1.1rem"}} />
                                        </Link></TableCell>

                                    </TableRow>

                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                : <div>No sessions available</div>}


            </div>
        )
    }
}
