export const content = {
    homeScreenContent: {
        "en-US": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "en-GB": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "es-ES": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "es-MX": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "fr-FR": {
            title: "",
            subTitle: "La plateforme de chat qui brise la barrière de la langue."
        },
        "de-DE": {
            title: "",
            subTitle: "Die Chat-Plattform, die die Sprachbarriere durchbricht."
        },
        "it-IT": {
            title: "",
            subTitle: "La piattaforma di chat che abbatte la barriera linguistica."
        },
        "hi-IN": {
            title: "",
            subTitle: "वह चैट प्लेटफ़ॉर्म जो भाषा की बाधा तोड़ता है"
        }
    },
    loginScreenContent: {
        "en-US": {
            title: "Log In",
            loginButton: "Log In",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset"
        },
        "en-GB": {
            title: "Log In",
            loginButton: "Log In",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset"
        },
        "es-ES": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña"
        },
        "es-MX": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña"
        },
        "fr-FR": {
            title: "Connectez-vous",
            loginButton: "Connectez-vous",
            email: "Adresse électronique",
            password: "Mot de Passe",
            passwordResetLinkText: "Demande de réinitialisation du mot de passe"
        },
        "de-DE": {
            title: "Anmelden",
            loginButton: "Anmelden",
            email: "E-Mail-Adresse",
            password: "Passwort",
            passwordResetLinkText: "Passwort zurücksetzen"
        },
        "it-IT": {
            title: "Accedi",
            loginButton: "Accedi",
            email: "Indirizzo Email",
            password: "Password",
            passwordResetLinkText: "Richiesta di reimpostazione della password"
        }
        ,
        "hi-IN": {
            title: "लॉग इन करें",
            loginButton: "लॉग इन करें",
            email: "ईमेल पता",
            password: "पासवर्ड",
            passwordResetLinkText: "पासवर्ड रीसेट का अनुरोध करें"
        }
    },
    registerScreenContent: {
        "en-US": {
            title: "Register",
            registerButton: "Register",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "I have read and agree to the Privacy Policy and Terms of Service."
        },
        "en-GB": {
            title: "Register",
            registerButton: "Register",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "I have read and agree to the Privacy Policy and Terms of Service."
        },
        "es-ES": {
            title: "Registrarse",
            registerButton: "Registrarse",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "He leído y acepto la Política de privacidad y los Términos de servicio."
        },
        "es-MX": {
            title: "Registrarse",
            registerButton: "Registrarse",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "He leído y acepto la Política de Privacidad y los Términos de Servicio."
        },
        "fr-FR": {
            title: "S'inscrire",
            registerButton: "S'inscrire",
            email: "Adresse électronique",
            password: "Mot de Passe",
            displayName: "Nom d'affichage",
            privacyPolicyLinkText: "Politique de confidentialité",
            termsOfServiceLinkText: "Conditions d'utilisation",
            agreementStatement: "J'ai lu et j'accepte la politique de confidentialité et les conditions d'utilisation."
        },
        "de-DE": {
            title: "Registrieren",
            registerButton: "Registrieren",
            email: "E-Mail-Adresse",
            password: "Passwort",
            displayName: "Anzeigename",
            privacyPolicyLinkText: "Datenschutzrichtlinie",
            termsOfServiceLinkText: "Nutzungsbedingungen",
            agreementStatement: "Ich habe die Datenschutzrichtlinien und Nutzungsbedingungen gelesen und stimme ihnen zu."
        },
        "it-IT": {
            title: "Registrarsi",
            registerButton: "Registrarsi",
            email: "Indirizzo Email",
            password: "Password",
            displayName: "Nome visualizzato",
            privacyPolicyLinkText: "Informativa sulla privacy",
            termsOfServiceLinkText: "Termini di servizio",
            agreementStatement: "Ho letto e accetto la politica sulla privacy e le condizioni di servizio."
        }
        ,
        "hi-IN": {
            title: "पंजीकरण करें",
            registerButton: "पंजीकरण करें",
            email: "ईमेल पता",
            password: "पासवर्ड",
            displayName: "प्रदर्शन नाम",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा की शर्तें",
            agreementStatement: "मैंने गोपनीयता नीति और सेवा की शर्तें पढ़ ली हैं और मैं उनसे सहमत हूँ।"
        }
    },
    resetPasswordScreenContent: {
        "en-US": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page"
        },
        "en-GB": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page"
        },
        "es-ES": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión"
        },
        "es-MX": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión"
        },
        "fr-FR": {
            title: "Réinitialisation du mot de passe",
            resetButton: "Demande de réinitialisation du mot de passe",
            email: "Adresse électronique",
            loginLinkText: "Retourner à la page de connexion"
        },
        "de-DE": {
            title: "Passwort zurücksetzen",
            resetButton: "Passwort zurücksetzen",
            email: "E-Mail-Adresse",
            loginLinkText: "Zurück zur Anmeldeseite"
        },
        "it-IT": {
            title: "Reimposta password",
            resetButton: "Richiesta di reimpostazione della password",
            email: "Indirizzo Email",
            loginLinkText: "Torna alla pagina di accesso"
        }
        ,
        "hi-IN": {
            title: "पासवर्ड रीसेट",
            resetButton: "पासवर्ड रीसेट का अनुरोध करें",
            email: "ईमेल पता",
            loginLinkText: "लॉगिन पेज पर वापस जाएँ"
        }
    },
    leftSidebarContent: {
        "en-US": {
            notificationsSubtext: "Notifications",
            autoplaySubtext: "Autoplay",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "New Chat",
            accountSubtext: "Account",
            settingsSubtext: "Settings",
            siteInfoSubtext: "Site Info"
        },
        "en-GB": {
            notificationsSubtext: "Notifications",
            autoplaySubtext: "Autoplay",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "New Chat",
            accountSubtext: "Account",
            settingsSubtext: "Settings",
            siteInfoSubtext: "Site Info"
        },
        "es-ES": {
            notificationsSubtext: "Notificaciones",
            autoplaySubtext: "Reproducción automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Chat Nuevo",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración",
            siteInfoSubtext: "Información del sitio"

        },
        "es-MX": {
            notificationsSubtext: "Notificaciones",
            autoplaySubtext: "Reproducción automática",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Chat Nuevo",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración",
            siteInfoSubtext: "Información del sitio"
        },
        "fr-FR": {
            notificationsSubtext: "Notifications",
            autoplaySubtext: "Lecture automatique",
            groupSubtext: "Groupe",
            translateSubtext: "Traduire",
            sharedSubtext: "Écran partagé",
            addSubtext: "Nouveau Chat",
            accountSubtext: "Compte",
            settingsSubtext: "Paramètres",
            siteInfoSubtext: "Informations sur le site"
        },
        "de-DE": {
            notificationsSubtext: "Benachrichtigungen",
            autoplaySubtext: "Automatische Wiedergabe",
            groupSubtext: "Gruppe",
            translateSubtext: "Übersetzen",
            sharedSubtext: "Gemeinsamer Bildschirm",
            addSubtext: "Neuer Chat",
            accountSubtext: "Konto",
            settingsSubtext: "Einstellungen",
            siteInfoSubtext: "Website-Informationen"
        },
        "it-IT": {
            notificationsSubtext: "Notifiche",
            autoplaySubtext: "Riproduzione automatica",
            groupSubtext: "Gruppo",
            translateSubtext: "Traduci",
            sharedSubtext: "Schermo condiviso",
            addSubtext: "Chat Nuova",
            accountSubtext: "Conto",
            settingsSubtext: "Impostazioni",
            siteInfoSubtext: "Informazioni sul sito"
        },
        "hi-IN": {
            notificationsSubtext: "सूचनाएँ",
            autoplaySubtext: "स्वतः चलना",
            groupSubtext: "समूह",
            translateSubtext: "अनुवाद करें",
            sharedSubtext: "साझा स्क्रीन",
            addSubtext: "नया चैट",
            accountSubtext: "खाता",
            settingsSubtext: "सेटिंग्स",
            siteInfoSubtext: "साइट जानकारी"
        }

    },
    chatScreenContent: {
        "en-US": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen",
            noMessagesGroup: "There are no messages yet. Send a message below or add new members here:",
            noMessagesTranslation: "There are no translations yet. Submit some text to translate below.",
            myLanguageTabLabel: "My Language",
        },
        "en-GB": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen",
            noMessagesGroup: "There are no messages yet. Send a message below or add new members here:",
            noMessagesTranslation: "There are no translations yet. Submit some text to translate below.",
            myLanguageTabLabel: "My Language"
        },
        "es-ES": {
            notificationsModalHeader: "Notificaciones",
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida",
            noMessagesGroup: "Aún no hay mensajes. Envía un mensaje a continuación o añade nuevos miembros aquí:",
            noMessagesTranslation: "No hay traducciones aún. Envía un texto para traducir a continuación.",
            myLanguageTabLabel: "Mi Idioma"
        },
        "es-MX": {
            notificationsModalHeader: "Notificaciones",
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida",
            noMessagesGroup: "Aún no hay mensajes. Envía un mensaje a continuación o añade nuevos miembros aquí:",
            noMessagesTranslation: "No hay traducciones aún. Envía un texto para traducir a continuación.",
            myLanguageTabLabel: "Mi Idioma"
        },
        "fr-FR": {
            notificationsModalHeader: "Notifications",
            conversationModalHeader: "Conversations de Groupe",
            translateModalHeader: "Traductions Personnelles",
            sharedModalHeader: "Affichage Partagé",
            noMessagesGroup: "Il n'y a pas encore de messages. Envoyez un message ci-dessous ou ajoutez de nouveaux membres ici:",
            noMessagesTranslation: "Il n'y a pas encore de traductions. Envoyez un texte à traduire ci-dessous.",
            myLanguageTabLabel: "Ma Langue"

        },
        "de-DE": {
            notificationsModalHeader: "Benachrichtigungen",
            conversationModalHeader: "Gemeinsamer Bildschirm",
            translateModalHeader: "Persönliche Übersetzungen",
            sharedModalHeader: "Gemeinsamer Bildschirm",
            noMessagesGroup: "Es gibt noch keine Nachrichten. Senden Sie unten eine Nachricht oder fügen Sie hier neue Mitglieder hinzu:",
            noMessagesTranslation: "Es gibt noch keine Übersetzungen. Senden Sie unten einen Text zur Übersetzung.",
            myLanguageTabLabel: "Meine Sprache"
        },
        "it-IT": {
            notificationsModalHeader: "Notifiche",
            conversationModalHeader: "Conversazioni di Gruppo",
            translateModalHeader: "Traduzioni Personali",
            sharedModalHeader: "Schermo Condiviso",
            noMessagesGroup: "Non ci sono ancora messaggi. Invia un messaggio qui sotto o aggiungi nuovi membri qui:",
            noMessagesTranslation: "Non ci sono ancora traduzioni. Invia un testo da tradurre qui sotto.",
            myLanguageTabLabel: "La Mia Lingua"
        },
        "hi-IN": {
            notificationsModalHeader: "सूचनाएँ",
            conversationModalHeader: "समूह वार्तालाप",
            translateModalHeader: "व्यक्तिगत अनुवाद",
            sharedModalHeader: "साझा स्क्रीन",
            noMessagesGroup: "अब तक कोई संदेश नहीं है। नीचे एक संदेश भेजें या यहां नए सदस्य जोड़ें:",
            noMessagesTranslation: "अब तक कोई अनुवाद नहीं है। नीचे अनुवाद करने के लिए कुछ पाठ भेजें।",
            myLanguageTabLabel: "मेरी भाषा"
        },
    },
    accountPanelContent: {
        "en-US": {title: "My Account"},
        "en-GB": {title: "My Account"},
        "es-ES": {title: "Mi Cuenta"},
        "es-MX": {title: "Mi Cuenta"},
        "fr-FR": {title: "Mon compte"},
        "de-DE": {title: "Mein Konto"},
        "it-IT": {title: "Il mio Account"},
        "hi-IN": {title: "मेरा खाता"}
    },
    settingsPanelContent: {
        "en-US": {
            title: "Settings",
            defaultLanguage: "My Default Language",
            voiceGender: "My Voice Gender",
            autoPlay: "Autoplay Audio Responses",
            male: "Male",
            female: "Female",
            yes: "Yes",
            no: "No",
            save: "Save",
            cancel: "Cancel"
        },
        "en-GB": {
            title: "Settings",
            defaultLanguage: "My Default Language",
            voiceGender: "My Voice Gender",
            autoPlay: "Autoplay Audio Responses",
            male: "Male",
            female: "Female",
            yes: "Yes",
            no: "No",
            save: "Save",
            cancel: "Cancel"
        },
        "es-ES": {
            title: "Configuración",
            defaultLanguage: "Mi Idioma por Defecto",
            voiceGender: "Mi tipo de voz",
            autoPlay: "Reproducción automática",
            male: "Hombre",
            female: "Mujer",
            yes: "Sí",
            no: "No",
            save: "Guardar",
            cancel: "Cancelar"
        },
        "es-MX": {
            title: "Configuración",
            defaultLanguage: "Mi Idioma por Defecto",
            voiceGender: "Mi tipo de voz",
            autoPlay: "Reproducción automática",
            male: "Hombre",
            female: "Mujer",
            yes: "Sí",
            no: "No",
            save: "Guardar",
            cancel: "Cancelar"
        },
        "fr-FR": {
            title: "Paramètres",
            defaultLanguage: "Ma langue par défaut",
            voiceGender: "Mon genre vocal",
            autoPlay: "Lecture automatique",
            male: "Homme",
            female: "Femme",
            yes: "Oui",
            no: "Non",
            save: "Commence",
            cancel: "Annule"
        },
        "de-DE": {
            title: "Einstellungen",
            defaultLanguage: "Meine Standardsprache",
            voiceGender: "Mein Sprachgeschlecht",
            autoPlay: "Automatische Wiedergabe",
            male: "Männlich",
            female: "Weiblich",
            yes: "Ja",
            no: "Nein",
            save: "Speichern",
            cancel: "Stornieren"
        },
        "it-IT": {
            title: "Impostazioni",
            defaultLanguage: "La mia lingua predefinita",
            voiceGender: "Il mio genere di voce",
            autoPlay: "Riproduzione automatica",
            male: "Maschio",
            female: "Femmina",
            yes: "Sì",
            no: "No",
            save: "Salva",
            cancel: "Annulla"
        },
        "hi-IN": {
            title: "सेटिंग्स",
            defaultLanguage: "मेरी डिफ़ॉल्ट भाषा",
            voiceGender: "मेरी आवाज का लिंग",
            autoPlay: "स्वतः चलना",
            male: "पुरुष",
            female: "महिला",
            yes: "हाँ",
            no: "नहीं",
            save: "बचाओ",
            cancel: "रद्द करें"
        }
    },
    sessionsTableContent: {
        "en-US": {
            nameHeader: "Name",
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "en-GB": {
            nameHeader: "Name",
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "es-ES": {
            nameHeader: "Nombre",
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "es-MX": {
            nameHeader: "Nombre",
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "fr-FR": {
            nameHeader: "Nom",
            memberHeader: "Membres",
            languageHeader: "Langues",
        },
        "de-DE": {
            nameHeader: "Name",
            memberHeader: "Mitglieder",
            languageHeader: "Sprachen",
        },
        "it-IT": {
            nameHeader: "Nome",
            memberHeader: "Membri",
            languageHeader: "Lingue",
        },
        "hi-IN": {
            nameHeader: "नाम",
            memberHeader: "सदस्य",
            languageHeader: "भाषाएँ",
        },
    },
    chatInviteScreenContent: {
        "en-US": {
            title: "Join the Conversation!",
            languageField: "My Language",
            chatCodeField: "Chat Invite Code",
            joinButton: "Join"
        },
        "en-GB": {
            title: "Join the Conversation!",
            languageField: "My Language",
            chatCodeField: "Chat Invite Code",
            joinButton: "Join"
        },
        "es-ES": {
            title: "¡Únete a la conversación!",
            languageField: "Mi idioma",
            chatCodeField: "Código de invitación al chat",
            joinButton: "Únete"
        },
        "es-MX": {
            title: "¡Únete a la conversación!",
            languageField: "Mi idioma",
            chatCodeField: "Código de invitación al chat",
            joinButton: "Únete"
        },
        "fr-FR": {
            title: "Rejoignez la conversation !",
            languageField: "Ma langue",
            "chatCodeField": "Code d'invitation au chat",
            joinButton: "Rejoignez"
        },
        "de-DE": {
            title: "Mach mit beim Gespräch!",
            languageField: "Meine Sprache",
            chatCodeField: "Chat-Einladungscode",
            joinButton: "Tritt bei"
        },
        "it-IT": {
            title: "Partecipa alla conversazione!",
            languageField: "La mia lingua",
            chatCodeField: "Codice invito chat",
            joinButton: "Unisciti"
        },
        "hi-IN": {
            title: "बातचीत में शामिल हों!",
            languageField: "मेरी भाषा",
            chatCodeField: "चैट आमंत्रण कोड",
            joinButton: "जुड़ें"
        }
    },
    chatNameEditScreenContent: {
        "en-US": {
            title: "Edit Chat Name",
            description: "Edit the chat name. The change will be reflected to all members of the chat.",
            chatNameField: "Chat Name",
            saveButtonText: "Save",
            cancelButtonText: "Cancel"

        },
        "en-GB": {
            title: "Edit Chat Name",
            description: "Edit the chat name. The change will be reflected to all members of the chat.",
            chatNameField: "Chat Name",
            saveButtonText: "Save",
            cancelButtonText: "Cancel"
        },
        "es-ES": {
            title: "Editar Nombre del Chat",
            description: "Edita el nombre del chat. El cambio se reflejará en todos los miembros del chat.",
            chatNameField: "Nombre del Chat",
            saveButtonText: "Guardar",
            cancelButtonText: "Cancelar"
        },
        "es-MX": {
            title: "Editar Nombre del Chat",
            description: "Edita el nombre del chat. El cambio se reflejará en todos los miembros del chat.",
            chatNameField: "Nombre del Chat",
            saveButtonText: "Guardar",
            cancelButtonText: "Cancelar"

        },
        "fr-FR": {
            title: "Nom du chat",
            description: "Modifiez le nom du chat. Le changement sera visible pour tous les membres du chat.",
            chatNameField: "Nom du chat",
            saveButtonText: "Commence",
            cancelButtonText: "Annule"

        },
        "de-DE": {
            title: "Chat-Namen bearbeiten",
            description: "Bearbeite den Chat-Namen. Die Änderung wird für alle Chat-Mitglieder sichtbar sein.",
            chatNameField: "Chat-Name",
            saveButtonText: "Speichern",
            cancelButtonText: "Stornieren"

        },
        "it-IT": {
            title: "Modifica nome chat",
            description: "Modifica il nome della chat. La modifica sarà visibile a tutti i membri della chat.",
            chatNameField: "Nome della chat",
            saveButtonText: "Salva",
            cancelButtonText: "Annulla"

        },
        "hi-IN": {
            title: "चैट का नाम बदलें",
            description: "चैट का नाम बदलें। बदलाव चैट के सभी सदस्यों को दिखाई देगा।",
            chatNameField: "चैट नाम",
            saveButtonText: "बचाओ",
            cancelButtonText: "रद्द करें"

        },
    },
    dashboardCardContent: {
        "en-US": {
            createChatTitle: "Create a Chat!",
            createChatDescription: "To creat a chat session, select the type and choose your options.",
            createChatPrimaryButtonText: "Get Started",
            joinChatTitle: "Join a Chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Join",
            recentGroupChatTitle: "Recent Group Chats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Show All",
            recentTranslatorChatTitle: "Recent Translations",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Show All",
            recentSharedChatTitle: "Recent Shared Screen Chats",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Show All"

        },
        "en-GB": {
            createChatTitle: "Create a Chat!",
            createChatDescription: "To creat a chat session, select the type and choose your options.",
            createChatPrimaryButtonText: "Get Started",
            joinChatTitle: "Join a Chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Join",
            recentGroupChatTitle: "Recent Group Chats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Show All",
            recentTranslatorChatTitle: "Recent Translations",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Show All",
            recentSharedChatTitle: "Recent Shared Screen Chats",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Show All"

        },
        "es-ES": {
            createChatTitle: "Crea un chat!",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            createChatPrimaryButtonText: "Empieza",
            joinChatTitle: "¡Únete a un chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Empieza",
            recentGroupChatTitle: "Chats de grupo recientes",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar todo",
            recentTranslatorChatTitle: "Traducciones recientes",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar todo",
            recentSharedChatTitle: "Chats recientes de pantalla compartida",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar todo",
        },
        "es-MX": {
            createChatTitle: "Crea un chat!",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            createChatPrimaryButtonText: "Empieza",
            joinChatTitle: "¡Únete a un chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Empieza",
            recentGroupChatTitle: "Chats de grupo recientes",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostrar todo",
            recentTranslatorChatTitle: "Traducciones recientes",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostrar todo",
            recentSharedChatTitle: "Chats recientes de pantalla compartida",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostrar todo",

        },
        "fr-FR": {
            createChatTitle: "Créez un chat !",
            createChatDescription: "Pour créer une session de chat, sélectionnez le type et choisissez vos options.",
            createChatPrimaryButtonText: "Commencez",
            joinChatTitle: "Rejoignez un chat !",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Commencez",
            recentGroupChatTitle: "Discussions de groupe récentes",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Afficher tout",
            recentTranslatorChatTitle: "Traductions récentes",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Afficher tout",
            recentSharedChatTitle: "Discussions d'écran partagé récentes",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Afficher tout",

        },
        "de-DE": {
            createChatTitle: "Erstelle einen Chat!",
            createChatDescription: "Um einen Chat zu starten, wähle den Typ und deine Optionen aus.",
            createChatPrimaryButtonText: "Legen Sie los",
            joinChatTitle: "Tritt einem Chat bei!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Legen Sie los",
            recentGroupChatTitle: "Aktuelle Gruppenchats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Alles anzeigen",
            recentTranslatorChatTitle: "Aktuelle Übersetzungen",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Alles anzeigen",
            recentSharedChatTitle: "Kürzlich geteilte Bildschirmchats",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Alles anzeigen",

        },
        "it-IT": {
            createChatTitle: "Crea una chat!",
            createChatDescription: "Per creare una chat, seleziona il tipo e scegli le tue opzioni.",
            createChatPrimaryButtonText: "Inizia",
            joinChatTitle: "Partecipa a una chat!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "Inizia",
            recentGroupChatTitle: "Recent Group Chats",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "Mostra tutto",
            recentTranslatorChatTitle: "Traduzioni recenti",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "Mostra tutto",
            recentSharedChatTitle: "Chat di condivisione schermo recenti",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "Mostra tutto",

        },
        "hi-IN": {
            createChatTitle: "चैट बनाएँ!",
            createChatDescription: "चैट सेशन बनाने के लिए, टाइप चुनें और अपने विकल्प चुनें।",
            createChatPrimaryButtonText: "शुरू करें",
            joinChatTitle: "चैट में शामिल हों!",
            joinChatDescription: "",
            joinChatPrimaryButtonText: "शुरू करें",
            recentGroupChatTitle: "हाल के समूह चैट",
            recentGroupChatDescription: "",
            recentGroupChatPrimaryButtonText: "सब दिखाएँ",
            recentTranslatorChatTitle: "हाल के अनुवाद",
            recentTranslatorChatDescription: "",
            recentTranslatorChatPrimaryButtonText: "सब दिखाएँ",
            recentSharedChatTitle: "हाल ही में साझा की गई स्क्रीन चैट",
            recentSharedChatDescription: "",
            recentSharedChatPrimaryButtonText: "सब दिखाएँ",

        },
    },
    userWidgetsTopRight: {
        "en-US": {
            logoutButtonText: "Logout",
        },
        "en-GB": {
            logoutButtonText: "Logout",
        },
        "es-ES": {
            logoutButtonText: "Cerrar sesión",

        },
        "es-MX": {
            logoutButtonText: "Cerrar sesión",

        },
        "fr-FR": {
            logoutButtonText: "Déconnexion",

        },
        "de-DE": {
            logoutButtonText: "Abmelden",

        },
        "it-IT": {
            logoutButtonText: "Esci",

        },
        "hi-IN": {
            logoutButtonText: "लॉग आउट करें",

        }
    },
    createChatPanel: {
        "en-US": {
            title: "Create a Chat",
            createChatDescription: "To creat a chat session, select the type and choose your options.",
            chatNameLabel: "Chat Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "en-GB": {
            title: "Create a Chat",
            chatNameLabel: "Chat Name",
            myLanguageLabel: "My Language",
            translateToLanguageLabel: "Translate to Language",
            createChatDescription: "To creat a chat session, select the type and choose your options.",
            cancelButton: "Cancel",
            createButton: "Create",
        },
        "es-ES": {
            title: "Crea un chat",
            chatNameLabel: "Nombre del chat",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",

        },
        "es-MX": {
            title: "Crea un chat",
            chatNameLabel: "Nombre del chat",
            myLanguageLabel: "Mi idioma",
            translateToLanguageLabel: "Traducir al idioma",
            createChatDescription: "Para crear una sesión de chat, selecciona el tipo y elige tus opciones.",
            cancelButton: "Cancelar",
            createButton: "Crear",
        },
        "fr-FR": {
            title: "Créez un chat",
            chatNameLabel: "Nom du chat",
            myLanguageLabel: "Ma langue",
            translateToLanguageLabel: "Traduire en langue",
            createChatDescription: "Pour créer une session de chat, sélectionnez le type et choisissez vos options.",
            cancelButton: "Annule",
            createButton: "Créer",

        },
        "de-DE": {
            title: "Erstelle einen Chat",
            chatNameLabel: "Chat-Name",
            myLanguageLabel: "Meine Sprache",
            translateToLanguageLabel: "Übersetzen in Sprache",
            createChatDescription: "Um einen Chat zu starten, wähle den Typ und deine Optionen aus.",
            cancelButton: "Stornieren",
            createButton: "Erstellen",

        },
        "it-IT": {
            title: "Crea una chat",
            chatNameLabel: "Nome della chat",
            myLanguageLabel: "La mia lingua",
            translateToLanguageLabel: "Traduci in lingua",
            createChatDescription: "Per creare una chat, seleziona il tipo e scegli le tue opzioni.",
            cancelButton: "Annulla",
            createButton: "Crea",

        },
        "hi-IN": {
            title: "चैट बनाएँ!",
            chatNameLabel: "चैट नाम",
            myLanguageLabel: "मेरी भाषा",
            translateToLanguageLabel: "भाषा में अनुवाद करें",
            createChatDescription: "चैट सेशन बनाने के लिए, टाइप चुनें और अपने विकल्प चुनें।",
            cancelButton: "रद्द करें",
            createButton: "बनाएँ",

        }
    },
    joinChatPanel: {
        "en-US": {
            title: "Join a Chat",
            cancelButton: "Cancel",
            createButton: "Join",
        },
        "en-GB": {
            title: "Join a Chat",
            cancelButton: "Cancel",
            createButton: "Join",
        },
        "es-ES": {
            title: "Únete a un chat",
            cancelButton: "Cancelar",
            createButton: "Únete",

        },
        "es-MX": {
            title: "Únete a un chat",
            cancelButton: "Cancelar",
            createButton: "Únete",
        },
        "fr-FR": {
            title: "Rejoignez un Chat",
            cancelButton: "Annule",
            createButton: "Rejoignez-nous",

        },
        "de-DE": {
            title: "Mach mit im Chat",
            cancelButton: "Stornieren",
            createButton: "Mach mit!",

        },
        "it-IT": {
            title: "Unisciti a una Chat",
            cancelButton: "Annulla",
            createButton: "Unisciti",

        },
        "hi-IN": {
            title: "चैट में जुड़ो",
            cancelButton: "रद्द करें",
            createButton: "शामिल हो",
        }
    },
    updateChatLanguagePanel: {
        "en-US": {
            title: "Update Chat Language",
            description: "Select your preferred language.",
            languageSelectLabel: "My Language",
            cancelButton: "Cancel",
            createButton: "Update",
        },
        "en-GB": {
            title: "Update Chat Language",
            description: "Select your preferred language.",
            languageSelectLabel: "My Language",
            cancelButton: "Cancel",
            createButton: "Update",
        },
        "es-ES": {
            title: "Actualizar idioma del chat",
            description: "Selecciona tu idioma preferido.",
            languageSelectLabel: "Mi idioma",
            cancelButton: "Cancelar",
            createButton: "Actualiza",

        },
        "es-MX": {
            title: "Actualizar idioma del chat",
            description: "Selecciona tu idioma preferido.",
            languageSelectLabel: "Mi idioma",
            cancelButton: "Cancelar",
            createButton: "Actualiza",
        },
        "fr-FR": {
            title: "Mettre à jour le langage de la conversation",
            description: "Sélectionnez votre langue préférée.",
            languageSelectLabel: "Ma langue",
            cancelButton: "Annule",
            createButton: "Mets à jour",

        },
        "de-DE": {
            title: "Chat-Sprache aktualisieren",
            description: "Wählen Sie Ihre bevorzugte Sprache aus.",
            languageSelectLabel: "Meine Sprache",
            cancelButton: "Stornieren",
            createButton: "Update",

        },
        "it-IT": {
            title: "Aggiorna la lingua della chat",
            description: "Scegli la tua lingua preferita.",
            languageSelectLabel: "La mia lingua",
            cancelButton: "Annulla",
            createButton: "Aggiorna",

        },
        "hi-IN": {
            title: "चैट लैंग्वेज अपडेट करो",
            description: "अपनी पसंद की भाषा चुनें",
            languageSelectLabel: "मेरी भाषा",
            cancelButton: "रद्द करें",
            createButton: "अपडेट करो",
        }
    },

    micListeningOptionsPanel: {
        "en-US": {
            title: "Listening Options",
            subtitle: "Select your preferred microphone setting.",
            micTriggered: "The microphone is button-triggered and closes after you speak.",
            micContinuous: "Microphone remains on continuously after activated.",
            cancelButton: "Cancel",
            saveButton: "Save",
        },
        "en-GB": {
            title: "Listening Options",
            subtitle: "Select your preferred microphone setting.",
            micTriggered: "The microphone is button-triggered and closes after you speak.",
            micContinuous: "Microphone remains on continuously after activated.",
            cancelButton: "Cancel",
            saveButton: "Save",
        },
        "es-ES": {
            title: "Opciones de escucha",
            subtitle: "Selecciona tu configuración de micrófono preferida.",
            micTriggered: "El micrófono se activa con un botón y se cierra después de que hablas.",
            micContinuous: "El micrófono permanece encendido continuamente después de activarse.",
            cancelButton: "Cancelar",
            saveButton: "Guardar",

        },
        "es-MX": {
            title: "Opciones de escucha",
            subtitle: "Selecciona tu configuración de micrófono preferida.",
            micTriggered: "El micrófono se activa con un botón y se cierra después de que hablas.",
            micContinuous: "El micrófono permanece encendido continuamente después de activarse.",
            cancelButton: "Cancelar",
            saveButton: "Guardar",
        },
        "fr-FR": {
            title: "Options d'écoute",
            subtitle: "Sélectionnez votre paramètre de microphone préféré.",
            micTriggered: "Le micro reste allumé en permanence après activation.",
            micContinuous: "Le micro se déclenche par bouton et se coupe après que tu parles.",
            cancelButton: "Annule",
            saveButton: "Commence",

        },
        "de-DE": {
            title: "Hör-Optionen",
            subtitle: "Wählen Sie Ihre bevorzugte Mikrofoneinstellung.",
            micTriggered: "\n" +
                "Das Mikrofon wird durch einen Knopf aktiviert und schaltet sich nach dem Sprechen aus.",
            micContinuous: "Das Mikrofon bleibt nach der Aktivierung dauerhaft eingeschaltet.",
            cancelButton: "Stornieren",
            saveButton: "Speichern",

        },
        "it-IT": {
            title: "Unisciti a una Chat",
            subtitle: "Seleziona la tua impostazione del microfono preferita.",
            micTriggered: "Il microfono si attiva con un pulsante e si chiude dopo che hai parlato.",
            micContinuous: "Il microfono rimane acceso continuamente dopo l'attivazione.",
            cancelButton: "Annulla",
            saveButton: "Salva",

        },
        "hi-IN": {
            title: "सुनने के विकल्प",
            subtitle: "अपनी पसंदीदा माइक्रोफोन सेटिंग का चयन करें।",
            micTriggered: "माइक्रोफ़ोन बटन से चालू होता है और आप बोलना बंद करने के बाद बंद हो जाता है।",
            micContinuous: "माइक्रोफ़ोन चालू होने के बाद लगातार चालू रहता है।",
            cancelButton: "रद्द करें",
            saveButton: "बचाओ",
        }
    }




};
