import React, {Component, useEffect} from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TranslateIcon from '@mui/icons-material/Translate';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import Avatar from "@mui/material/Avatar";
import CloseIcon from '@mui/icons-material/Close';
import {AvatarGroup, Button, IconButton, Tooltip} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import eventBus from "../../helpers/eventBus";


export default class ChatHeader extends Component {
    state = {
        rows: [],
        sessionObject: {},
        currentSessionId: "",
        editNameOpen: false,
        spur: false,
        isOwner: false

    }


    componentDidMount()
    {
        this.loadSession()
        this.setState({remount: true})
        eventBus.on("updateHeader", (uObj) => {
            this.loadSession()
        });
    }


    loadSession = () => {
        //console.log("updateHeader event received")
        let self = this;

        if (this.props.detailObj.hasOwnProperty("sessionMembers")) {
            this.setState({isOwner: false})
            this.props.detailObj.sessionMembers.forEach(
                (member) => {
                    if (member.userId === localStorage.getItem("uid")) {
                        if (member.role === "owner") {
                            this.setState({isOwner: true})
                        }
                    }
                }
            )
        }



        if (!this.state.currentSessionId) {
            this.setState({currentSessionId: localStorage.getItem("cSessionId")})
        }

        let sessions = JSON.parse(localStorage.getItem("chatSessions"));
        sessions.forEach((session) => {
            if (session.id === localStorage.getItem("cSessionId")) {
                self.setState({sessionObject: session, currentSessionId: localStorage.getItem("cSessionId")})
            }
        })
    }

    /*
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ChatHeader mounted")
        if (!this.state.currentSessionId) {
            this.setState({currentSessionId: localStorage.getItem("cSessionId")})
        }

        if (this.state.currentSessionId !== nextProps.chatId || this.state.spur !== nextProps.spur) {
            //console.log("in if", this.props)
            let self = this;
            this.setState({spur: nextProps.spur})
            let sessions = JSON.parse(localStorage.getItem("chatSessions"));
            sessions.forEach((session) => {
                if (session.id === localStorage.getItem("cSessionId")) {
                    self.setState({sessionObject: session, currentSessionId: localStorage.getItem("cSessionId")})
                }
            })
            return true;
        }
        return false;
    }

     */


    getSessionTypeImage = (sessionType) => {
        if (this.state.sessionObject) {
            if (this.state.sessionObject.sessionType === "conversation") {
                return (<QuestionAnswerIcon />)
            } else if (this.state.sessionObject.sessionType === "translate") {
                return (<TranslateIcon />)
            } else if (this.state.sessionObject.sessionType === "shared") {
                return (<InterpreterModeIcon />)
            }
        }
    }


    render() {
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                { this.state.sessionObject.id ?
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <div style={{paddingRight: "10px", paddingTop: "10px"}}><this.getSessionTypeImage /></div>
                        <div style={{fontSize: ".9rem"}}>{this.props.chatName}
                            {this.state.isOwner ?
                                <IconButton onClick={() => {
                                    this.props.openChatRename(this.state.sessionObject)
                                }}>
                                    <EditIcon style={{fontSize: "16px"}} />
                                </IconButton>
                                    : null
                            }
                            <br />
                            <div style={{fontSize: ".7rem"}}>
                                [{ this.state.sessionObject && this.state.sessionObject.hasOwnProperty("languages") ? this.state.sessionObject.languages.join(', ') : "" }]
                             <span style={{display: "none"}}>Created: {this.state.sessionObject ? new Date(this.state.sessionObject.startTime).toLocaleString('en-US') : ""}</span>
                            </div>
                        </div>
                        {(this.state.sessionObject && this.state.sessionObject.hasOwnProperty("sessionType") && this.state.sessionObject.sessionType === "conversation") ?
                            <div style={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                paddingTop: "7px",
                                borderLeft: "1px solid #ddd"
                            }}>
                                <AvatarGroup max={3}>
                                {this.state.sessionObject.sessionMembers.map((member) => {
                                return (<Tooltip title={member.displayName}><Avatar
                                    key={member.userId}
                                    sx={{
                                        bgcolor: "#ddd",
                                        width: 22,
                                        height: 22,
                                        fontSize: ".7rem",
                                        color: "#333",
                                        textAlign: "center",
                                        display: "inline-block",
                                        paddingTop: "5px"
                                    }}
                                    alt={member.displayName}
                                >{member.displayName[0]}</Avatar></Tooltip>)
                            })
                                }</AvatarGroup></div> : null
                        }

                        {(this.state.sessionObject && this.state.sessionObject.sessionType === "conversation") ?
                        <div style={{marginLeft: "5px"}}>
                            <IconButton
                                    onClick={() => {this.props.openChatInvite(this.state.sessionObject)}}
                                    ><GroupAddIcon /></IconButton>
                        </div> : null
                        }

                        {this.state.isOwner ?
                            <div style={{marginLeft: "5px", borderLeft: "1px solid #ddd"}}>
                                <IconButton
                                    onClick={() => {
                                        this.props.openChatDelete(this.state.sessionObject)
                                    }}
                                ><DeleteForeverIcon/></IconButton>
                            </div>
                            : null
                        }


                    </div>
                    : null
                }

                    <div><IconButton onClick={this.props.clearSession}><CloseIcon/></IconButton></div>


            </div>
        )
    }
}
