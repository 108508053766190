import {Button, Divider, IconButton, TextField} from "@mui/material";
import ScrollableFeed from "react-scrollable-feed";
import MessageHistory from "../messageHistory";
import React, {useEffect, useState, useRef} from "react";
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AssistantIcon from '@mui/icons-material/Assistant';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import HearingIcon from '@mui/icons-material/Hearing';
import CloseIcon from '@mui/icons-material/Close';
import Groups2Icon from '@mui/icons-material/Groups2';
import TranslateIcon from '@mui/icons-material/Translate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import {db} from "../../helpers/firebase";
import { v4 as uuidv4 } from 'uuid';
import {req_get_history} from "../../helpers/requests";
import useSpeechToText, { ResultType } from "../Hooks";
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import "./chat.css"
import Typography from "@mui/material/Typography";
import CreateChat from "../dialogs/createChat";
import {clearChatSession, clearChatSessionUser, createChatSession, createChatSessionUser} from "../../helpers/session";
import Drawer from "@mui/material/Drawer";
import SessionSelector from "../embeddedSelectors/sessionSelector";
import BottomNavigation from '@mui/material/BottomNavigation';
import ChatHeader from "../headers/chatHeader";
import AccountPanel from "../dialogs/accountPanel";
import SettingsPanel from "../dialogs/settingsPanel";
import InviteMember from "../dialogs/inviteMember";
import ChatDelete from "../dialogs/chatDelete";
import {content} from "../../globals/content";
import EditChatName from "../dialogs/editChatName";
import eventBus from "../../helpers/eventBus";
import DisplayNotifications from "../dialogs/displayNotifications";
import Badge from '@mui/material/Badge';
import JoinChat from "../dialogs/joinChat";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SiteInfo from "../dialogs/siteInfo";
import ChangeChatLanguage from "../dialogs/changeChatLanguage";
import ListeningOptions from "../dialogs/listeningOptions";

export default function Chat(props) {

    const [language, setLanguage] = useState(props.selLang);
    const [otherLanguage, setOtherLanguage] = useState("en-US");
    const [messages, setMessages] = useState([]);
    const [chatSessionId, setChatSessionId] = useState(props.chatSessionId);
    const [waiting, setWaiting] = useState(false);
    const [projectId, setProjectId] = React.useState(window.location.pathname.replace("/", "").split("_")[0]);
    const [workflowStageId, setWorkflowStageId] = React.useState("");
    const [isVideoActive, setIsVideoActive] = React.useState(false);
    const [isAudioActive, setIsAudioActive] = React.useState(true);
    const [entryText, setEntryText] = useState("");
    const [location, setLocation] = React.useState({latitude: "", longitude: ""});
    const [accountId, setAccountId] = useState(localStorage.getItem("selectedAccount"));
    //const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loaded, setLoaded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [createChatOpen, setCreateChatOpen] = React.useState(false);
    const [editMicOpen, setEditMicOpen] = React.useState(false);
    const [joinChatOpen, setJoinChatOpen] = React.useState(false);
    const [changeLanguageOpen, setChangeLanguageOpen] = React.useState(false);
    const [notificationsOpen, setNotificationsOpen] = React.useState(false);
    const [chatInviteOpen, setChatInviteOpen] = React.useState(false);
    const [chatDeleteOpen, setChatDeleteOpen] = React.useState(false);
    const [chatRenameOpen, setChatRenameOpen] = React.useState(false);
    const [sessionCode, setSessionCode] = React.useState("");
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [accountDrawerOpen, setAccountDrawerOpen] = React.useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);
    const [drawerSessionType, setDrawerSessionType] = React.useState(true);
    const [drawerSessionDisplay, setDrawerSessionDisplay] = React.useState("");
    const [chatDetailObj, setChatDetailObj] = React.useState(null);
    const [otherInvoker, setOtherInvoker] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [autoplay, setAutoplay] = React.useState(localStorage.getItem("autoplay"));
    const [myLanguage, setMyLanguage] = React.useState(localStorage.getItem(""));
    const [sessionSpur, setSessionSpur] = React.useState(false);
    const [infoPanelOpen, setInfoPanelOpen] = React.useState(false);
    const [scrollButtonVisible, setScrollButtonVisible] = React.useState(false);
    const [micContinuous, setMicContinuous] = React.useState(localStorage.getItem("micContinuous") || false);
    const [suppressRestart, setSuppressRestart] = React.useState(false);
    const [recordingState, setRecordingState] = React.useState(false);
    const [alertNewMessages, setAlertNewMessages] = React.useState(false);


    const scrollerRef = useRef(null);

    const {
        error,
        interimResult,
        isRecording,
        results,
        isSpeaking,
        couldNotUnderstand,
        startSpeechToText,
        stopSpeechToText
    } = useSpeechToText({
        continuous: micContinuous,
        crossBrowser: true,
        googleApiKey: process.env.REACT_APP_API_KEY,
        speechRecognitionProperties: { interimResults: true, lang:language },
        useLegacyResults: false,
        enableAutomaticPunctuation: true,
        diarizationConfig: {

        },
        googleCloudRecognitionConfig: {
            encoding: 'LINEAR16',
            languageCode: language,
            enableAutomaticPunctuation: true,
            diarizationConfig: {

            },
        },
    });

    useEffect(() => {
        refreshSessionData()
    }, [chatSessionId]);

    useEffect(() => {


        if (micContinuous == "true" && !suppressRestart) {

            startSpeechToText(language);
            setRecordingState(true)
        }
        /*
        else {
            console.log("in this else", suppressRestart, isRecording)
            if (suppressRestart && isRecording) {
                console.log("in this else if")
                setRecordingState(false)
                setSuppressRestart(true)
            } else {

                console.log("in this else else", suppressRestart, isRecording)
                setRecordingState(isRecording)
                setSuppressRestart(false)
            }
        }

         */
    }, [isRecording]);

    const refreshSessionData = (refresh = false) => {
        console.log("refresh")
        let sessions = JSON.parse(localStorage.getItem("chatSessions")) || [];
        let tempLang = language;
        sessions.forEach((session) => {
            if (session.id === localStorage.getItem("cSessionId")) {
                session.sessionMembers.forEach((member) => {
                    if (member.userId === localStorage.getItem("uid")){
                        setLanguage(member.language);
                        tempLang = member.language;
                        if (session.sessionType === "shared")
                        {
                            session.languages.forEach((lang) => {
                                if (lang !== member.language) {
                                    setOtherLanguage(lang);
                                }
                            })
                        }
                        updateSession(localStorage.getItem("cSessionId"), tempLang, session);
                    }
                })
            }
        })
        //eventBus.dispatch("spurSession", {})

    }

    useEffect(() => {
        console.log("results", results)
        let last = {};
        if (results.length > 0) {
            last = results.pop();
            console.log("last", last)
            if (last.hasOwnProperty("transcript") && last.transcript !== "") {
                setEntryText(last.transcript)
                setRef(true, last.transcript, null, "user", "")
                if (micContinuous != "true") {
                    console.log("not continuous")
                    setSuppressRestart(true);
                    setRecordingState(false);
                    stopSpeechToText(true);


                }
            }
        }
    }, [results])

    useEffect(() => {
        eventBus.dispatch("showAlert", { open: true, message: "Sorry, I could not understand. Please repeat that.", type: "error", vertical: "bottom", horizontal: "center", timeout: 2000 })
    }, [couldNotUnderstand])

    useEffect(() => {
        console.log("spurred")
    }, [props.updSession])

    const toggleLeftDrawer = (anchor, open, sessionType) => {
        setDrawerSessionType(sessionType);
        if (sessionType === "translate") {
            setDrawerSessionDisplay("Personal Translate Sessions");
        } else if (sessionType === "conversation") {
            setDrawerSessionDisplay("Group Conversations");
        } else if (sessionType === "shared") {
            setDrawerSessionDisplay("Shared Display Conversations");
        }
        setDrawerOpen(open);
    }

    const toggleAccountDrawer = (anchor, open) => {
        setAccountDrawerOpen(open);
    }

    const toggleSettingsDrawer = (anchor, open) => {
        setSettingsDrawerOpen(open);
    }

    const infoPanelToggle = (open) => {
        console.log("infoPanelToggle", open)
        setInfoPanelOpen(open);
    }

    const setQuery = (e) => { setEntryText(e.target.value) }

    const onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMessage(e)
        }
    }

    const updateMicSettings = () => {
        setEditMicOpen(false);
        setMicContinuous(localStorage.getItem("micContinuous"));
    }


    const updateSession = (sessionId, language, row = null) => {
        //console.log("updateSession", sessionId, language, row)
        if (row) {
            setChatDetailObj(row);
        }

        clearSession();
        createChatSession(sessionId);
        createChatSessionUser(localStorage.getItem("uid"));
        setChatSessionId(sessionId);
        if (row.hasOwnProperty("sessionMembers")) {
            setULanguage(row.sessionMembers);
        }
        setAccountId(row.accountId)
        setCreateChatOpen(false);
        setLanguage(language);
        setDrawerOpen(false);
        eventBus.dispatch("updateHeader", {rerun: false});
        loadData(sessionId, row.accountId);
    }

    const setULanguage = (members) => {
        members.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")) {
                setMyLanguage(member.language);

            }
        })
    }

    const clearSession = () => {
        setFirstLoad(true);
        setSessionCode("");

        setChatSessionId("");
        clearChatSession();
        clearChatSessionUser();
    }

    useEffect(() => {
        setChatSessionId(props.chatSessionId);
        toggleAutoplay();
        eventBus.on("triggerChatRefresh", (uObj) => {
            refreshSessionData(false);
        });

        refreshSessionData(true)

        eventBus.on("spurSession", (uObj) => {
            setSessionSpur(!sessionSpur);
        });

        if (localStorage.getItem("autoLoad") === "true") {
            console.log("autoload is true")
            let load_sessions = JSON.parse(localStorage.getItem("chatSessions"));
            let selectedSession = {};
            load_sessions.forEach((session) => {
                console.log(session.id, localStorage.getItem("cSessionId"))
                if (session.id === localStorage.getItem("cSessionId")) {
                    console.log("matches")
                    selectedSession = session;
                }
            })
            console.log(selectedSession);
            let lang = "";

            if (selectedSession && selectedSession.hasOwnProperty("sessionMembers")){
                (selectedSession["sessionMembers"]).forEach((member) => {
                    console.log(member)
                    if (member.userId === localStorage.getItem("uid")){
                        lang = member.language;
                    }
                })
            }

            updateSession(localStorage.getItem("cSessionId"), lang, selectedSession);
        }

    }, [])




    const toggleAudio = () => { setIsAudioActive(!isAudioActive); }

    const loadData = (sessionId, acctId) => {
        const params = new URLSearchParams(window.location.search);
        let newMessages = [];

        eventBus.dispatch("showLoader", {showLoader: true})
        req_get_history(sessionId)
            .then(function (result) {
                let tempFirstLoad = true;
                if (result.status === 200) {

                    let historyData = result.data.data;
                    let chatHistory = historyData["history"]["exchangeLog"] || [];
                    //setMessagesLoaded(false)
                    chatHistory.forEach((chat) => {

                        if (chat["conveyor"] === "user") {
                            newMessages.push({sender: "user", ...chat});
                        }
                        if (chat["conveyor"] === "assistant") {
                            newMessages.push({sender: "assistant", ...chat});
                        }
                    });


                    if (newMessages.length === 0) {
                        tempFirstLoad = true;
                    }
                    //setFirstLoad(false);

                    try {
                        let messageId = uuidv4().toString()
                        db.ref("/translation/" + acctId + "/" + sessionId + "/c").on("value", snapshot => {

                            if(snapshot.val() !== null) {
                                if (snapshot.val().hasOwnProperty("bypass") && snapshot.val().bypass === true) {
                                    setWaiting(false);
                                } else {
                                    if (snapshot.val() && tempFirstLoad) {
                                        if (newMessages.length === 0) {
                                            newMessages.push(snapshot.val());
                                        }
                                        if (snapshot.val().sender !== "user") {
                                            setFirstLoad(false);
                                            setWaiting(false);
                                        }
                                    } else {

                                        if (!tempFirstLoad) {
                                            newMessages.push(snapshot.val());
                                            setRefresh(!refresh);
                                        }
                                        setFirstLoad(false);
                                        setWaiting(false);
                                    }
                                }


                            }


                            setFirstLoad(false);
                            setMessages([...newMessages]);
                            tempFirstLoad = false;
                            if (scrollButtonVisible){
                                setAlertNewMessages(true);
                            }
                        });

                        setLoaded(true);
                        setFirstLoad(false);

                    } catch (error) {
                        console.log("fail", error)
                    }
                }

                //setMessagesLoaded(true)
                setLoaded(true);
                eventBus.dispatch("showLoader", {showLoader: false})
            })
    }


    const Waiter = () => { return (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>) }

    const sendMessage = (e) => {
        e.preventDefault();
        if (entryText === "") { return; }
        setRef(true, entryText, null, "user", "");
    }

    const sendFuncMessage = (message, messageType, messageObject) => {
        setRef(true, message, null, "userFunc", messageType, messageObject);
    }

    const setRef = (createId, message, messageId, sender, messageType, messageObject={}) => {

        if (createId) { messageId = uuidv4().toString() }
        console.log("message", message)
        if (message !== undefined) {
            db.ref(`translation/${accountId}/${chatSessionId}/c`).set({
                message: message,
                messageId: messageId,
                id: messageId,
                messageType: messageType,
                workflowStageId: ["user", "userFunc"].includes(sender) ? workflowStageId : "",
                sender: sender,
                initialId: "",
                senderId: localStorage.getItem("cSessionUserId"),
                senderDisplayName: localStorage.getItem("displayName"),
                projectId: projectId,
                payload: {audio: isAudioActive, location: location},
                language: otherInvoker ? otherLanguage : language,
                sent: new Date().toISOString()
            });

            setEntryText("");
            setWaiting(true);
        }
        // set timer
    }

    const openChatInvite = () => { setChatInviteOpen(true); }
    const openChatDelete = () => { setChatDeleteOpen(true); }
    const openChatRename = () => { setChatRenameOpen(true); }

    const toggleAutoplay = () => {

        let ap = "true"

        if (localStorage.getItem("autoplay") !== "") {
            ap = "true" ? localStorage.getItem("autoplay") === "false" : "false";
            localStorage.setItem("autoplay", ap)
        }
        setAutoplay(ap);
        console.log(ap)

    }

    const toggleNotifications = () => {
        setNotificationsOpen(!notificationsOpen);

    }

    const scrollToBottom = () => {
        scrollerRef.current.scrollToBottom();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="homeContentContainer">
            <div style={{display: "flex", flexDirection: "row", gap: 10, marginLeft: "5px", marginRight: "5px"}}>
                <div style={{
                    minWidth: 32,
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(100dvh - 200px)",
                    maxHeight: "calc(100dvh - 200px)",
                    justifyContent: "space-between",
                }}>

                    <div style={{paddingTop: "10px"}}>

                        <IconButton style={{ display: "none"}} aria-describedby={id} variant="contained" onClick={toggleNotifications}>
                            <Badge color="secondary" badgeContent={7}>
                                <NotificationsIcon style={{color: "#444"}} />
                            </Badge>
                        </IconButton>
                        <div style={{fontSize: ".6rem", display: "none"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].notificationsSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={toggleAutoplay}>
                            {autoplay === "true" || autoplay === true ?
                                <VolumeUpIcon style={{color: "#758a00"}}/> :
                                <VolumeOffIcon style={{color: "#8a0000"}}/>
                            }

                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].autoplaySubtext}</div>

                        <Divider style={{marginTop: "5px", marginBottom: "5px"}} />

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleLeftDrawer("left", true, "conversation")}
                        }>
                            <QuestionAnswerIcon style={{color: "#444"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].groupSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleLeftDrawer("left", true, "translate")
                        }}>
                            <TranslateIcon style={{color: "#444"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].translateSubtext}</div>

                        <IconButton style={{ display: "none"}} aria-describedby={id} variant="contained" onClick={() => {
                            toggleLeftDrawer("left", true, "shared")
                        }}>
                            <InterpreterModeIcon style={{color: "#444"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem", display: "none"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].sharedSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            setCreateChatOpen(true)
                        }}>
                            <AddCircleIcon style={{color: "#444"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].addSubtext}</div>

                    </div>
                    <div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleAccountDrawer("left", true)}
                        }>
                            <ManageAccountsIcon style={{color: "#333"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].accountSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleSettingsDrawer("right", true)}
                        }>
                            <SettingsIcon style={{color: "#333"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].settingsSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            infoPanelToggle( true)}
                        }>
                            <InfoIcon style={{color: "#333"}} />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].siteInfoSubtext}</div>

                    </div>
                </div>



                <div style={{
                    border: "1px solid #e7e7e7",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    width: "100%"
                }}>

                    {chatSessionId && chatDetailObj !== null ?
                        <>

                            <div style={{
                                textAlign: "left",
                                backgroundColor: "#f7f7f7",
                                padding: "5px",
                                fontWeight: "bold",
                                borderBottom: "1px solid #aaa"
                            }}>
                                <ChatHeader detailObj={chatDetailObj} chatId={chatSessionId} openChatInvite={openChatInvite}
                                            chatName={chatDetailObj.name}
                                            openChatDelete={openChatDelete}
                                            openChatRename={openChatRename}
                                            clearSession={clearSession}
                                            spur={sessionSpur}/>
                            </div>
                            <div style={{minHeight: "calc(100dvh - 170px)", maxHeight: "calc(100dvh - 170px)", position: "relative"}}>
                                <div><div style={{
                                    position: "absolute",
                                    width: "150px",
                                    left: 0,
                                    right: 0,
                                    margin: "auto",

                                    backgroundColor: "#f7f7f7",
                                    borderLeft: "1px solid #ccc",
                                    borderRight: "1px solid #ccc",
                                    borderBottom: "1px solid #ccc",
                                    top: "0",
                                    zIndex: 1000,
                                    borderBottomRightRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    borderTopRightRadius: "0",
                                    borderTopLeftRadius: "0",
                                    fontSize: ".75rem",
                                }}>{content.chatScreenContent[myLanguage || "en-US"].myLanguageTabLabel}: {myLanguage} <IconButton onClick={() => {
                                    setChangeLanguageOpen(true)

                                }}>
                                    <EditIcon style={{fontSize: "12px"}} />
                                </IconButton></div>


                                    <div style={{
                                        position: "absolute",
                                        width: "30px",
                                        left: "195px",
                                        right: 0,
                                        margin: "auto",

                                        backgroundColor: "#f7f7f7",
                                        borderLeft: "1px solid #ccc",
                                        borderRight: "1px solid #ccc",
                                        borderBottom: "1px solid #ccc",
                                        top: "0",
                                        zIndex: 1000,
                                        borderBottomRightRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        borderTopRightRadius: "0",
                                        borderTopLeftRadius: "0",
                                        fontSize: ".8rem",
                                    }}> <IconButton

                                                    onClick={() => {
                                                        setEditMicOpen(true)
                                                    }}
                                    >
                                        <SettingsVoiceIcon style={{fontSize: "12px"}} />
                                    </IconButton></div>

                                </div>


                                {scrollButtonVisible ?
                                    <div
                                        className={"pulse"}
                                        style={{
                                            position: "absolute",
                                            width: "36px",
                                            right: 30,
                                            backgroundColor: "#333",
                                            animation: alertNewMessages ? "pulse 2s infinite" : "none",
                                            borderLeft: "1px solid #ccc",
                                            borderRight: "1px solid #ccc",
                                            borderBottom: "1px solid #ccc",
                                            borderTop: "0",
                                            top: "0",
                                            zIndex: 1000,
                                            borderBottomRightRadius: "5px",
                                            borderBottomLeftRadius: "5px",
                                            borderTopRightRadius: "0",
                                            borderTopLeftRadius: "0",
                                    }}><IconButton onClick={() => {
                                        setAlertNewMessages(false);
                                        scrollerRef.current.scrollToBottom();
                                    }}>
                                        <ExpandCircleDownIcon style={{fontSize: "16px", color: "#fff"}}/>
                                    </IconButton></div> : null
                                }

                                {messages.length === 0 ?
                                    <div style={{textAlign: "center", margin: "20px", border: "1px solid #41a887", fontSize: "1rem", padding: "10px", borderRadius: "5px"}}>

                                        {chatDetailObj.sessionType === "conversation" ?
                                            <>
                                                {content.chatScreenContent[myLanguage || "en-US"].noMessagesGroup}
                                                <IconButton style={{marginLeft: "10px", fontWeight: "bold"}}
                                                        onClick={() => {
                                                            openChatInvite(chatDetailObj)
                                                        }}>
                                                    <GroupAddIcon style={{fontSize: "30px", color: "#41a887"}}/>
                                                </IconButton>
                                            </> : null
                                        }

                                        {chatDetailObj.sessionType === "translate" ?
                                            <>
                                                {content.chatScreenContent[myLanguage || "en-US"].noMessagesTranslation}
                                            </> : null
                                        }
                                    </div>
                                    :
                                    <ScrollableFeed
                                        ref={scrollerRef}
                                        id="output"
                                        className={" chat_scroller "}
                                        onScroll={(atBottom) => {

                                              setScrollButtonVisible(!atBottom)
                                              setAlertNewMessages(false)

                                        }}
                                    >
                                        <div className={"inner-chat"} id={"inner-chat"} style={{
                                            width: "100%",
                                            paddingBottom: "0",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            marginTop: "10px",

                                        }}>
                                            <MessageHistory key={"messageHistory"}
                                                            messages={messages}
                                                            detailObj={chatDetailObj}
                                                            interfaceObject={{}}
                                                            firstLoad={true}
                                                            refresh={refresh}
                                                            myLanguage={myLanguage}
                                            />
                                            {waiting ?
                                                <div style={{
                                                    borderRadius: "10px",
                                                    marginBottom: "5px",
                                                    marginLeft: "100px",
                                                    marginRight: "100px",
                                                    textAlign: "center"
                                                }}>
                                                    <Waiter key={"waiterDisplay"}/>
                                                </div> : null
                                            }
                                            <div
                                                style={{clear: "both"}}
                                                id={"bottomRef"}/>
                                        </div>
                                    </ScrollableFeed>
                                }
                            </div>
                            <BottomNavigation>
                            <div style={{width: "100%", paddingLeft: 10, paddingRight: 10,
                                marginRight: "100px",
                                marginLeft: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "60px" : 0}}>

                                <TextField value={entryText}
                                           disabled={isSpeaking}
                                           onChange={(e) => {
                                               setQuery(e)
                                           }}

                                           style={{width: "100%", borderColor: "#ddeeff", backgroundColor: isSpeaking ? "#f7f7f7" : "#fff"}}
                                           onKeyDown={(e) => {
                                               onEnterPress(e)
                                           }}>
                                </TextField>



                                {chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ?
                                    <Button style={{
                                        position: "absolute",
                                        float: "left",
                                        left: "80px",
                                        marginTop: "0px",
                                        padding: 0,
                                        color: "#083f45",
                                        background: recordingState && otherInvoker ? "#c5d5b0" : "#fff"
                                    }}
                                            onClick={recordingState ?
                                                () => {
                                                    setRecordingState(false);
                                                    stopSpeechToText();

                                            } : () => {
                                                setOtherInvoker(true);
                                                startSpeechToText(otherLanguage);
                                                setRecordingState(true);
                                            }
                                            }>
                                        {isSpeaking && otherInvoker ? <div><HearingIcon style={{margin: 0, padding: 0}}/>
                                            <div style={{"fontSize": ".7rem"}}>{otherLanguage}</div>
                                        </div> : <div><KeyboardVoiceIcon />
                                            <div style={{"fontSize": ".7rem"}}>{otherLanguage}</div>
                                        </div>}
                                    </Button> : null
                                }

                                <Button style={{
                                    position: "absolute",
                                    float: "right",
                                    right: "45px",
                                    marginTop: "0px",
                                    padding: 0,
                                    color: "#083f45",
                                    background: recordingState && !otherInvoker ? "#c5d5b0"  : "#fff"
                                }}
                                            onClick={recordingState ?
                                                () => {
                                                    setSuppressRestart(true);
                                                    setRecordingState(false);
                                                    stopSpeechToText(true);

                                            } : () => {
                                                    setOtherInvoker(false);
                                                    startSpeechToText(language);
                                                    setRecordingState(true);
                                            }}>
                                    {isSpeaking && !otherInvoker ? <div><HearingIcon style={{margin: 0, padding: 0}} /><div style={{"fontSize": ".7rem"}}>{language}</div></div> : <div><MicIcon/><div style={{"fontSize": ".7rem"}}>{language}</div></div>}
                                </Button>
                                <IconButton disableRipple={true} disableFocusRipple={true} style={{
                                    position: "absolute",
                                    float: "right",
                                    right: "10px",
                                    marginTop: "2px",
                                    boxShadow: 'none',
                                    color: "#083f45"
                                }}
                                            onClick={(e) => {
                                                sendMessage(e)
                                            }}
                                ><SendIcon/></IconButton>
                            </div>
                            </BottomNavigation>
                        </> : <div style={{padding: 10, display: "flex", gap: 10, flexFlow: "wrap", justifyContent: "center"}}>

                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].createChatTitle}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button style={{margin: "0 auto"}} onClick={() => {setCreateChatOpen(true)}} size="small">{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].createChatPrimaryButtonText}</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        {content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].joinChatTitle}
                                    </Typography>

                                </CardContent>
                                <CardActions >
                                    <Button style={{margin: "0 auto"}} onClick={() => {setJoinChatOpen(true)}} size="small">{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].joinChatPrimaryButtonText}</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        {content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentGroupChatTitle}
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button style={{margin: "0 auto"}} onClick={() => {toggleLeftDrawer("left", true, "conversation")}}
                                            size="small">{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentGroupChatPrimaryButtonText}</Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        {content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentTranslatorChatTitle}
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        style={{margin: "0 auto"}}
                                        onClick={() => {toggleLeftDrawer("left", true, "translate")}}
                                        size="small">{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentTranslatorChatPrimaryButtonText}</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5", display: "none" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        {content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentSharedChatTitle}
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        style={{margin: "0 auto"}}
                                        onClick={() => {toggleLeftDrawer("left", true, "shared")}}
                                        size="small">{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentSharedChatPrimaryButtonText}</Button>
                                </CardActions>
                            </Card>
                        </div>
                    }
                </div>
                <Drawer
                    PaperProps={{
                        sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
                    }}
                    anchor={"right"}
                    open={accountDrawerOpen}
                    onClose={() => {toggleAccountDrawer("right", false)}}
                >
                    <AccountPanel toggleAccountDrawer={toggleAccountDrawer} />
                </Drawer>

                <Drawer
                    PaperProps={{
                        sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
                    }}
                    anchor={"right"}
                    open={settingsDrawerOpen}
                    onClose={() => {toggleSettingsDrawer("right", false)}}
                >
                    <SettingsPanel toggleSettingsDrawer={toggleSettingsDrawer} />
                </Drawer>

            </div>

            <div
                style={{
                    border: "1px solid #e7e7e7",
                    width: "50%",
                    margin: '20px auto',
                    textAlign: 'center',
                    display: 'none'
                }}
            >
                <ul>
                    {results.map((result) => (
                        <li key={result.timestamp}>{result.transcript}</li>
                    ))}
                    {interimResult && <li>{interimResult}</li>}
                </ul>
            </div>




            {editMicOpen ? <ListeningOptions open={editMicOpen} updateSession={updateSession} close={() => {updateMicSettings()}} /> : null }

            {createChatOpen ? <CreateChat open={createChatOpen} updateSession={updateSession} close={() => {setCreateChatOpen(false)}} /> : null }

            {joinChatOpen ? <JoinChat open={joinChatOpen} updateSession={updateSession} close={() => {setJoinChatOpen(false)}} /> : null }

            {changeLanguageOpen ? <ChangeChatLanguage myLanguage={myLanguage} sessionCode={chatDetailObj.inviteId} open={changeLanguageOpen} updateSession={updateSession} close={() => {setChangeLanguageOpen(false)}} /> : null }

            {chatInviteOpen ? <InviteMember open={chatInviteOpen} chatObj={chatDetailObj} updateSession={updateSession} close={() => {setChatInviteOpen(false)}} /> : null }

            {chatDeleteOpen ? <ChatDelete open={chatDeleteOpen} chatObj={chatDetailObj} clearSession={clearSession} close={() => {setChatDeleteOpen(false)}} /> : null }

            {chatRenameOpen ? <EditChatName open={chatRenameOpen} chatObj={chatDetailObj} clearSession={clearSession} close={() => {
                    setChatRenameOpen(false);
            }} /> : null }

            {infoPanelOpen ? <SiteInfo open={infoPanelOpen} close={() => {setInfoPanelOpen(false)}} /> : null }



            <Drawer
                PaperProps={{
                    sx: { maxWidth: "400px", width: "100%", height: "500px", maxHeight: "100%", marginTop: "45px", borderRadius: "3px"},
                }}
                anchor={"left"}
                open={notificationsOpen}
                onClose={() => {toggleNotifications()}}
            >
                <div style={{padding: "5px"}}>
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].notificationsModalHeader}</Typography>
                </div>
                <DisplayNotifications />
            </Drawer>

            <Drawer
                PaperProps={{
                    sx: { maxWidth: "400px", width: "100%", height: "500px", maxHeight: "100%", marginTop: "45px", borderRadius: "3px"},
                }}
                anchor={"left"}
                open={drawerOpen}
                onClose={() => {toggleLeftDrawer("left", false, "")}}
            >
                <div style={{padding: "5px"}}>

                    {drawerSessionType === "conversation" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].conversationModalHeader}</Typography> :
                        null
                    }
                    {drawerSessionType === "translate" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].translateModalHeader}</Typography> :
                        null
                    }
                    {drawerSessionType === "shared" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].sharedModalHeader}</Typography> :
                        null
                    }
                </div>
                <SessionSelector sessionType={drawerSessionType} joinSession={updateSession} />
            </Drawer>

        </div>
    )
}
