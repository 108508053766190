export function clearLogout(logoutType = "clear") {

    localStorage.removeItem("plan");
    localStorage.removeItem("ownerEmail");

    // if acctChange and save settings do the right thing
    let settings = JSON.parse(localStorage.getItem("settings"));

    if (logoutType !== "acctChange") {
        console.log("do logout")
        localStorage.removeItem("rtoken");
        localStorage.removeItem("ownedAccount");
        localStorage.removeItem("selectedAccount");
        localStorage.removeItem("accounts");
        localStorage.removeItem("settings");
        localStorage.removeItem("uid");
        localStorage.removeItem("autoLoad");
        localStorage.removeItem("autoPlay");
        localStorage.removeItem("chatSessions");
        localStorage.removeItem("displayName");
        localStorage.removeItem("ownerEmail");
        localStorage.removeItem("siteLanguage");



    }
}

export function setLocalStorageVals(userObj) {
    localStorage.setItem("rtoken", userObj.data.refreshToken);
    localStorage.setItem("uid", userObj.data.id);
    localStorage.setItem("settings", JSON.stringify(userObj.data.settings));
    localStorage.setItem("accounts", JSON.stringify(userObj.data.accounts));
    localStorage.setItem("ownedAccount", JSON.stringify(userObj.data.ownedAccount));
    localStorage.setItem("selectedAccount", JSON.stringify(userObj.data.ownedAccount));
    localStorage.setItem("plan", userObj.data.plan ? userObj.data.plan : "");
    localStorage.setItem("ownerEmail", userObj.data.email ? userObj.data.email : "");
    localStorage.setItem("displayName", userObj.data.displayName ? userObj.data.displayName : "");

}
