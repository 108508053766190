import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import ChatOptionSelector from "../embeddedSelectors/chatOptionSelector";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {
    req_create_session_id,
    req_join_session,
    req_personal_session_id,
    req_shared_session_id
} from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";


export default class CreateChat extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
        name: ""
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    componentDidMount() {
        this.setState({language: localStorage.getItem("siteLanguage") || "en-US"});
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }
    setPeerLanguage = (lang) => {
        this.setState({peerLanguage: lang});
    }

    updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
            this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language, {accountId: localStorage.getItem("selectedAccount")});

        } else {
            this.setState({chatSessionId: undefined});
        }
    }

    createSession = (vConversationType, vSessionCode, vLanguage) => {
        console.log("createSession invoked")
        let self = this;
        console.log(this.state.conversationType, this.state.sessionCode, this.state.language);
        if (this.state.conversationType === "remote") {
            console.log("Creating remote session", this.state.language);
            self.setState({ loading: true});
            req_create_session_id(this.state.language, this.state.name).then((response) => {
                console.log("req_create_session_id response", response);
                eventBus.dispatch("updateSessions", {});
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        } else if (this.state.conversationType === "shared") {
            console.log("Creating shared session", vLanguage);
            self.setState({ loading: true});
            eventBus.dispatch("updateSessions", {});
            req_shared_session_id(this.state.language, this.state.peerLanguage, this.state.name).then((response) => {
                //console.log("req_create_session_id response", response);
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        } else if (this.state.conversationType === "personal") {
            console.log("Creating translate session", this.state.language);
            self.setState({ loading: true});
            eventBus.dispatch("updateSessions", {});
            req_personal_session_id(this.state.language, this.state.peerLanguage, this.state.name).then((response) => {
                console.log("req_personal_session_id response", response);
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText style={{marginBottom: "15px"}}>
                        {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createChatDescription}

                    </DialogContentText>
                    <ChatOptionSelector setConversationInvoke={this.setConversationInvoke} mutliSelect={false} />

                    {this.state.conversationType === "join" ?
                        <div style={{width: "100%", margin: "10px auto 5px auto"}}>

                            <div style={{textAlign: "center"}}>
                                <TextField value={this.state.sessionCode}
                                           label={"Session Code Here"}
                                           onChange={(e) => {
                                               this.setState({sessionCode: e.target.value})
                                           }} style={{width: "300px"}}
                                >
                                </TextField>
                            </div>
                        </div>
                        : null
                    }

                    {this.state.conversationType ?
                        <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                            <div style={{textAlign: "center"}}>
                                <TextField value={this.state.name}
                                           label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].chatNameLabel}
                                           onChange={(e) => {
                                               this.setState({name: e.target.value})
                                           }} style={{width: "300px"}}
                                >
                                </TextField>
                            </div>
                        </div>
                        : null
                    }


                    {this.state.conversationType ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                              label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].myLanguageLabel} mutliSelect={false}/>
                        </div>
                        : null
                    }

                    {this.state.conversationType === "shared" || this.state.conversationType === "personal" ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.peerLanguage} setLanguage={this.setPeerLanguage}
                                              label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].translateToLanguageLabel} mutliSelect={true} />

                        </div>: null
                    }

                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                    <Button variant={"outlined"} onClick={this.createSession}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
