import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import CopyAllIcon from '@mui/icons-material/CopyAll';

import Link from "@mui/material/Link";

export default class InviteMember extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    handleCopy = () => {
        console.log("copy")
        navigator.clipboard.writeText("https://earthlingual.com/i/" + this.props.chatObj.inviteId);
    }


    render() {
        console.log(this.props.chatObj.inviteId)
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>Invite Chat Members</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        Copy invite link or send email to invitees.
                    </DialogContentText>

                    <div style={{fontSize: ".8rem", marginTop: "15px"}}>
                        <b>Chat Id:</b> {this.props.chatObj.inviteId}<br />
                        <b>Link:</b> <Link onClick={this.handleCopy}>https://earthlingual.com/i/{this.props.chatObj.inviteId}</Link>
                        <IconButton onClick={this.handleCopy}><CopyAllIcon /></IconButton>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} variant={"outlined"}>Close</Button>
                    <Button style={{display: "none"}} onClick={this.createSession}>Send</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
