import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import Link from "@mui/material/Link";
import {req_delete_session} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";

export default class SiteInfo extends Component {

    state = {
        open: true,

    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }


    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
            >
                <DialogTitle>Site Information</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>

                        EarthLingual is a product of Hexplo, LLC.<br /><br />

                        Copyright © Hexplo, LLC - (https://hexplo.com)<br /><br />

                        For information on custom solutions, investment, or partnership opportunities, please contact us at info@hexplo.com.

                        Links to Privacy Policy and Terms of Service
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
        );
    }
}
