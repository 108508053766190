import {Navigate, Route, Routes} from "react-router-dom";
import React, {Component} from "react";
import LogIn from "../components/login/login";
import Register from "../components/login/register";
import Resetpass from "../components/login/resetpass";
import Home from "../components/pages/home";
import Verify from "../components/login/verify";
import AcctVerify from "../components/login/acctVerify";
import ChatInvitation from "../components/login/chatInvitation";

const PrivateRoute = (path = "", { children }) => {
    let auth = !!localStorage.getItem("rtoken");
    let plan = !!localStorage.getItem("plan");
    let nav = "/"
    if (path.hasOwnProperty("redirect")) { nav = nav + "?redirect=" + path.redirect; }
    if (!auth){ return <Navigate to={nav} />; }
    else if (auth && !plan && ["acctverify", "pricing"].includes(path.path)) {
        return path.children;
    }
    else if (auth && !plan) { return <Navigate to="/pricing" />; }
    else { return path.children; }
}

export default class DynamicRoutes extends Component {
    render() {
        return (

            <Routes>
                <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>}/>
                <Route path="/login" element={<LogIn />}/>
                <Route path="/regverify" element={<Verify />}/>
                <Route path="/resetpass" element={<Resetpass />}/>
                <Route path="/register" element={<Register />}/>
                <Route path="/" element={<Home />}/>
                <Route path="/i" element={<PrivateRoute redirect={window.location.pathname}><ChatInvitation /></PrivateRoute>} />
                <Route path="/i/:inviteId" element={<PrivateRoute redirect={window.location.pathname}><ChatInvitation /></PrivateRoute>} />
                <Route path="/acctverify" element={<PrivateRoute path={"acctverify"}><AcctVerify /></PrivateRoute>}/>
            </Routes>
        )}
}

// <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>}/>
/*
                <Route path="/login" element={<LogIn />}/>
                <Route path="/regverify" element={<Verify />}/>
                <Route path="/resetpass" element={<Resetpass />}/>
                <Route path="/register" element={<Register />}/>
                <Route path="/" element={<Home />}/>

                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}/>
                <Route path="/my-accounts" element={<PrivateRoute><MyAccount /></PrivateRoute>}/>
                <Route path="/my-contributions" element={<PrivateRoute><MyContributions /></PrivateRoute>}/>
                <Route path="/invite-contributors" element={<PrivateRoute><Invite /></PrivateRoute>}/>
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>}/>
                <Route path="/settings/:subpage" element={<PrivateRoute><Settings /></PrivateRoute>} />
                */
