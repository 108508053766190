import React, { Component } from 'react';
import {v4 as uuidv4} from "uuid";
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopIcon from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Avatar from '@mui/material/Avatar';



export default class MessageHistory extends Component {
    state = {
        audioFiles: {},
        audioPending: [],
        activeAudio: false,
        currentAudioFile: "",
        currentAudioId: "",
        firstLoad: this.props.firstLoad,
        activeMessages: [],
        activeTranslations: {},
        translationTab: 0,
        activePanels: {}
    }


    generateAudio = (message, autoplay = false) => {

        let audioFiles = this.state.audioFiles;
        if (!this.state.audioFiles.hasOwnProperty(message.messageId) && !this.state.audioPending.includes(message.messageId)) {
            let audioPending = this.state.audioPending;
            audioPending.push(message.messageId)
            this.setSourceButtons({audioPending: [...audioPending]})
            let self = this;

            if (message.audioUrl !== "") {

                audioFiles[message.messageId] = {
                    messageId: message.messageId,
                    fileData: null,
                    text: message.message,
                    url: message.audioUrl
                }
                self.setState({audioFiles: {...audioFiles} })
                if (autoplay) {
                    console.log("autoplay")
                    self.playAudio(message.messageId, message.audioUrl)
                }
            } else {
                console.log("in else")
            }
        }
    }

    playAudio = (messageId, audioUrl = "") => {

        try {
            this.setState({
                currentAudioFile: audioUrl,
                currentAudioId: messageId + "_" + audioUrl,
                activeAudio: true
            })
            let audio = document.getElementById("audioPlayer");
            audio.src = audioUrl;
            //console.log("audio", audio)
            audio.muted = false;
            audio.autoPlay = true;
            audio.play();

            audio.onEnded = () => {
                console.log("audio ended");

            };
        } catch (e) {
            console.log("error", e)
        }
    }

    pauseAudio = (messageId) => {
        console.log("pauseAudio", messageId)
        let audio = document.getElementById("audioPlayer");
        audio.pause();
        this.setState({activeAudio: false})
    }

    resetAudio = () => {
        console.log("resetAudio")
        this.setState({activeAudio: false})
    }

    MessageBlock = (props) => {
        //console.log(props)
        //console.log(props.message)
        if (props.message.sender === "user") {
            return this.promptBubble(props.message)
        } else if (props.message.sender === "userFunc" || props.message.sender === "sessionFunc"){
            return this.userFuncBubble(props.message)
        }
        else if (props.message.sender === "userFunc"){
            return this.userFuncBubble(props.message)
        } else if (this.props.detailObj.sessionType === "conversation"){
            //else if (localStorage.getItem("cSessionUserId") === props.message.senderId && this.props.detailObj.sessionType === "conversation"){
            //console.log(localStorage.getItem("cSessionUserId"), props.message.senderId)
            //console.log(this.props.detailObj.sessionType, this.state.firstLoad)
            let runUpdate = false;
            if (this.state.activeTranslations.hasOwnProperty(props.message.initialId) && !this.state.firstLoad) {

                runUpdate = true;
                this.state.activeTranslations[props.message.initialId].translations.forEach((translation) => {
                    if (translation.language === props.message.language) {
                        runUpdate = false;
                    }
                })
            } else {
                runUpdate = true;
            }
            if (runUpdate) {

                let activeTranslationIds = this.state.activeTranslations;
                if (props.message.sender !== "user" && props.message.initialId) {
                    if (!activeTranslationIds.hasOwnProperty(props.message.initialId)) {
                        activeTranslationIds[props.message.initialId] = {
                            "translations": [ props.message ]
                        };
                    } else {
                        activeTranslationIds[props.message.initialId].translations.push(props.message)
                    }
                }
                this.setState({activeTranslations: activeTranslationIds, firstLoad: false})
            }

                // need to check the current user to see what language and only allow that one.
                this.generateAudio(props.message,
                    (props.index === (props.length - 1)
                        && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                    && ((localStorage.getItem("cSessionUserId") !== props.message.senderId) || this.props.detailObj.sessionType !== "conversation")
                        && !this.state.firstLoad
                        && this.props.myLanguage === props.message.language
                    ))

                return this.responseNull();
            //}
        }
        else {
            return this.responseBubble(props.message, props.index, props.length)
        }
    }

    showSources = (message) => {

        if (this.props.interfaceObject.hasOwnProperty("properties")
            && this.props.interfaceObject.properties.hasOwnProperty("showSources")
            && this.props.interfaceObject.properties["showSources"] === true){
            if (message.payload && Object.keys(message.payload).includes("sourcesx")) {
                if (message["payload"].hasOwnProperty("sourcesx")) {

                    console.log("has sources")
                    if (message.payload.sourcesx.length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    setSourceButtons = (props) => {
        //console.log("props:", props.message)
        //return props.message.payload.sourcesx.map((source) => {
        //    return <Button>test {source.doc}</Button>
        //})
        return null
    }

    /*
    isPlaying = (messageId) => {
        console.log("playing", messageId, Object.keys(this.state.audioFiles))

        Object.keys(this.state.audioFiles).forEach((key) => {
            if (this.state.audioFiles[key].messageId !== messageId) {
                let audio = document.getElementById(messageId);
                audio.pause()
            }
        })
        this.setState({activeAudio: true})
    }
    */

    responseBubbleSelector = (message, ind, len) => {
        if (this.state.activeMessages.includes(message.initialId))
        return (<div style={{}}>
            <div>translations: tab header</div>
            <div style={{
                padding: "5px",
                borderRadius: "5px",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                border: "1px solid #ddd",
                backgroundColor: "#f7f7f7",

                marginBottom: "15px",
                fontSize: ".8rem",
                marginLeft: "5px",
                marginRight: "5px",
                color: "#494949"
            }}>tab content</div>
        </div>)
    }

    responseNull = () => {
        return (<div style={{display: "none"}}></div>)
    }


    activatePanel = (initialId, panelId) => {
        console.log("activatePanel", initialId, panelId);
        let activePanels = this.state.activePanels;
        activePanels[initialId] = {"active": panelId};
        this.setState({activePanels: activePanels})

    }

    responseBubble = (message, ind, len) => {
        //console.log("response message", message)
        this.generateAudio(message, (ind === (len - 1)
            && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true) )
            && ((localStorage.getItem("cSessionUserId") !== message.senderId) || this.props.detailObj.sessionType !== "conversation")
            && !this.state.firstLoad)
        if (message.hasOwnProperty("message") && message.message !== "") {
            return (<div key={message.messageId}
                         style={{
                             padding: "5px",
                             borderRadius: "5px",
                             borderTopLeftRadius: "0",
                             borderTopRightRadius: "0",
                             textAlign: "left",
                             display: "flex",
                             flexDirection: "row",
                             border: "1px solid #ddd",
                             backgroundColor: "#f7f7f7",
                             marginBottom: "15px",
                             fontSize: ".8rem",
                             marginLeft: "10px",
                             marginRight: "5px",
                             color: "#494949"
                         }}
                         className={"chat chat-ai"}>
                <div style={{width: "50px", fontSize: ".7rem"}}>[{message.translatedTo || message.language}]</div>
                <div key={message.messageId} style={{width: "100%"}}
                     dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />').replaceAll("\n", '<br />')}}/>

                <div style={{borderRadius: "20px", width: "40px", textAlign: "right"}}>
                    {this.state.audioFiles.hasOwnProperty(message.messageId) ?
                        <div style={{textAlign: "right"}}>
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && !this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                   this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.pauseAudio(message.messageId + "_" + message.audioUrl);
                                }}>
                                    <StopIcon size={20}
                                              style={{fill: this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ? "green" : "inherit"}}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId !== message.messageId + "_" + message.audioUrl ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> : null
                            }
                        </div>
                        : <CircularProgress size={20}/>
                    }
                </div>

                {this.showSources(message) ?
                    <div style={{clear: "both", fontSize: ".7rem"}}>
                        Sources: <this.setSourceButtons message={message}/>
                    </div>
                    : null
                }
            </div>)
        }
    }


    promptBubble = (message) => {

        if (1==1) {
            return (<div><div style={{position: "relative", marginTop: "10px", marginLeft: "5px", border: "1px solid #9eb7bd", borderRadius: "5px"}}><div key={message.messageId}
                            style={{padding: "20px 5px 15px 5px", backgroundColor: "#fff", textAlign: "left", color: "#444", fontSize: ".8rem",  marginLeft: "20px", marginRight: "44px"}}
                         className={"chat chat-human"} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} />
                    <div style={{position: "absolute", color: "#111", top: "0", padding: "0px 6px 2px 5px", fontSize: ".7rem", marginLeft: "20px"}}>
                        {message.senderDisplayName + ": " + new Date(message.sent).toLocaleString()}
                    </div>
                <Avatar sx={{ width: 24, height: 24, position: "absolute", left: "-10px", top: "5px", fontSize: ".8rem", backgroundColor: "#5a596c" }}>{message.senderDisplayName[0] || ""}</Avatar>
            </div>
                <div style={{display: this.state.activeTranslations.hasOwnProperty(message.messageId) ? "block" : "none"}}>

                    { this.state.activeTranslations.hasOwnProperty(message.messageId) ?
                        <div style={{
                            padding: "0 5px 5px 5px",
                            borderRadius: "5px",
                            borderTopLeftRadius: "0",
                            borderTopRightRadius: "0",
                            textAlign: "left",
                            border: this.state.activePanels.hasOwnProperty(message.messageId) ? "1px solid #ddd" : "none",
                            backgroundColor: this.state.activePanels.hasOwnProperty(message.messageId) ? "#f7f7f7" : "transparent",

                            marginBottom: "15px",
                            fontSize: ".8rem",
                            marginRight: "5px",
                            marginLeft: "10px",

                            color: "#494949"
                        }}>
                            {this.props.detailObj.sessionType === "conversation" && this.state.activeTranslations[message.messageId].translations !== this.props.myLanguage ?
                                <div style={{display: "block"}}>

                                    {this.state.activeTranslations[message.messageId].translations.map((translation, index) => {
                                        return (<div style={{
                                            display: message.language !== translation.language ? "inline-block" : "none",
                                            border: "1px solid #ccc",
                                            padding: "1px",
                                            backgroundColor: this.state.activePanels.hasOwnProperty(translation.initialId) && this.state.activePanels[translation.initialId].active === translation.id ? "#627389" : "#f7f7f7",
                                            color: this.state.activePanels.hasOwnProperty(translation.initialId) && this.state.activePanels[translation.initialId].active === translation.id ? "#fff" : "inherit",
                                            marginRight: "2px",
                                            borderRadius: "5px",
                                            borderTopLeftRadius: "0",
                                            borderTopRightRadius: "0",
                                            borderTop: "none",
                                            minHeight: "20px",
                                            maxHeight: "22px !important",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            cursor: 'pointer'

                                        }}  index={translation.messageId} label={translation.language} onClick={() => {
                                            console.log(translation.initialId, translation.messageId, translation.id);
                                            this.activatePanel(translation.initialId, translation.id)}
                                        }>{translation.language}</div>)
                                    })

                                    }
                                </div> : null
                            }
                            {this.props.detailObj.sessionType === "conversation" ?
                                <div style={{display: "block"}}>
                                    {this.state.activeTranslations[message.messageId].translations.map((translation, index) => {

                                        return (<div key={translation.id} index={index}
                                                     value={this.state.translationTab}
                                                     style={{
                                                         padding: "1px",
                                                         marginRight: "2px",
                                                         display: this.state.activePanels.hasOwnProperty(translation.initialId) &&
                                                         this.state.activePanels[translation.initialId].active === translation.id &&
                                                         message.language !== translation.language ? "flex" : "none",
                                                         flexDirection: "row",
                                                         justifyContent: "space-between",
                                        }}><div>{translation.message}</div>

                                            {this.state.audioFiles.hasOwnProperty(translation.messageId) ?
                                                <div style={{textAlign: "right"}}>
                                                    {this.state.currentAudioId === translation.messageId + "_" + translation.audioUrl && !this.state.activeAudio ?
                                                        <IconButton style={{padding: "2px"}} onClick={(e) => {
                                                            this.playAudio(translation.messageId, translation.audioUrl);
                                                        }}>
                                                            <NotStartedIcon size={20}/>
                                                        </IconButton> :
                                                        null
                                                    }
                                                    {this.state.currentAudioId === translation.messageId + "_" + translation.audioUrl && this.state.activeAudio ?
                                                        <IconButton style={{padding: "2px"}} onClick={(e) => {
                                                            this.pauseAudio(translation.messageId + "_" + translation.audioUrl);
                                                        }}>
                                                            <StopIcon size={20}
                                                                      style={{fill: this.state.currentAudioId === translation.messageId + "_" + translation.audioUrl && this.state.activeAudio ? "green" : "inherit"}}/>
                                                        </IconButton> :
                                                        null
                                                    }
                                                    {this.state.currentAudioId !== translation.messageId + "_" + translation.audioUrl ?
                                                        <IconButton style={{padding: "2px"}} onClick={(e) => {
                                                            this.playAudio(translation.messageId, translation.audioUrl);
                                                        }}>
                                                            <NotStartedIcon size={20}/>
                                                        </IconButton> : null
                                                    }
                                                </div>
                                                : <CircularProgress size={20}/>
                                            }

                                        </div>)
                                    })}
                                </div> : null
                            }
                        </div> :
                        null
                    }

                </div>
            </div>)
        } else {
            //console.log("message", message)
            if (message.hasOwnProperty("message") && message.message !== "" && message.message !== null) {
                return (<div style={{position: "relative", marginTop: "10px", marginLeft: "5px", border: "1px solid #dcdcdc", borderRadius: "5px"}}>
                    <div key={message.messageId}
                         style={{
                             padding: "20px 5px 15px 5px",
                             marginLeft: "20px",
                             color: "#444",
                             textAlign: "left",
                             fontSize: ".8rem"
                         }}
                         className={"chat chat-human"}
                         dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}}/>
                    <div style={{
                        position: "absolute",
                        color: "#111",
                        top: "0",
                        padding: "0px 6px 2px 5px",
                        fontSize: ".7rem",
                        marginLeft: "20px"
                    }}>{message.senderDisplayName + ": " + new Date(message.sent).toLocaleString()}</div>
                    <Avatar sx={{  width: 24, height: 24, position: "absolute", left: "-10px", top: "5px", fontSize: ".8rem", backgroundColor: "#5a596c" }}>{message.senderDisplayName[0] || ""}</Avatar>
                </div>)
            }
        }
    }
    userFuncBubble = (message) => {
        return (<div className={"chat chat-human"} style={{padding: "3px", borderRadius: "10px", border: "1px dashed #aaa", color: "#555", marginLeft: "60px", marginRight: "60px"}}>
            <ChangeCircleIcon style={{fontSize: "20px", marginRight: "10px", marginTop: "5px"}} /><div style={{display: "inline-block", paddingTop: 0, marginBottom: "5px"}} key={message.messageId} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} /></div>)
    }



    UnsetFirstLoad = () => {

        let messageIds = [];
        let activeTranslationIds = []
        this.props.messages.forEach((message) => {
            if (message.sender === "user"){
                messageIds.push(message.messageId)
            }

            if (message.sender !== "user" && message.initialId) {
                if (!activeTranslationIds.hasOwnProperty(message.initialId)) {
                    activeTranslationIds[message.initialId] = {
                        "translations": [
                            message
                        ]
                    };
                } else {
                    activeTranslationIds[message.initialId].translations.push(message)
                }
            }
        })


        this.setState({activeMessages: messageIds, activeTranslations: activeTranslationIds, firstLoad: false})
        return (<></>)
    }

    render() {
        //console.log("messages", this.props.messages)
        return (<>

            <audio id="audioPlayer" controls autostart="0" onEnded={this.resetAudio} style={{width: 150, height: 25, display: "none"}}>
                {this.state.hasOwnProperty("currentAudioFile") && this.state.currentAudioFile !== "" ?
                  <source
                    src={this.state.currentAudioFile}
                    type="audio/mp3" />
                  : null
                }
            </audio>
            {this.props.messages.map((message, index) => {
                return <this.MessageBlock key={uuidv4()} message={message} index={index} length={this.props.messages.length} />
            })}

            {this.state.firstLoad ? <this.UnsetFirstLoad /> : null}
        </>)
    }
}
