import React, {Component} from "react";
import {FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import Flag from 'react-world-flags';
import InputIcon from '@mui/icons-material/Input';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import TranslateIcon from '@mui/icons-material/Translate';

export default class ChatOptionSelector extends Component {
    state = {
        conversationList: [
            //{conversationCode: "join", name: "Join a Conversation with ID", image: InputIcon},
            {conversationCode: "remote", name: "New Remote Conversation", image: CoPresentIcon},
            //{conversationCode: "shared", name: "New Shared Screen Conversation", image: InterpreterModeIcon},
            {conversationCode: "personal", name: "Personal Translator", image: TranslateIcon}
        ]
    }


    render() {
        return (
            <div style={{

                 width: 300, margin: "0 auto"
            }}>
                <FormControl sx={{ m: 0, width: 300 }}>
                    <InputLabel htmlFor="demo-multiple-name-label">Select Chat Type</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={this.props.language}
                        input={<OutlinedInput label="Language" />}
                        onChange={(e) => {
                            this.props.setConversationInvoke(e.target.value);
                        }}
                        style={{textAlign: "left"}}

                    >
                        {this.state.conversationList.map((conv) => (
                            <MenuItem
                                key={conv.conversationCode + "_" + conv.name}
                                value={conv.conversationCode}
                            >
                                <div style={{display: "flex", flexDirection: "row"}}><conv.image style={{maxHeight: "20px", maxWidth: "30px", marginRight: "10px", marginLeft: "10px", borderRadius: "3px"}} /> {conv.name}</div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}
